import '../../pages/pageStyles/LandingPage.css';
// import logo from '../../assets/logo_cropped_modified.png';
import logo from '../../assets/modern_formats/logo_cropped_modified.webp';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';

const HeaderSection = () => (
  <>
    <Container maxWidth="xl">
      <Stack alignItems="center" py={5}>
        <img alt="Dyslexico logo" style={{ width: '100%' }} src={logo}></img>
        <Typography
          variant="body1"
          py={2}
          sx={{ letterSpacing: '2px', fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}
        >
          Corrections by Dyslexics for Dyslexics
        </Typography>
        <Link to="/textEditor" style={{ margin: '3rem' }}>
          <Button variant="contained" color="error" size="large" sx={{ textTransform: 'unset', fontSize: '1.25rem' }}>
            <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
              Try it now
            </Typography>
            <CallMadeIcon fontSize="inherit" style={{ marginLeft: '8px' }} />
          </Button>
        </Link>
      </Stack>
    </Container>
  </>
);

export default HeaderSection;
