import React, { useEffect, useState, ReactNode  } from 'react';
import { LandingPageFin } from '../../pages/LandingFin';
import { useLocation } from 'react-router-dom';
import Nav from '../navBar/Nav';
import { Footer } from './Footer';

const LandingPageWrapper = () => {
    const [shouldRender, setShouldRender] = useState(false);
    const location = useLocation();
    
    // handle bug with lingering css from account page messing up landing page appearance
    useEffect(() => {
        const hasRefreshed = sessionStorage.getItem('hasRefreshed');
        if (location.pathname === '/' && (!hasRefreshed || hasRefreshed === 'false')) {
            sessionStorage.setItem('hasRefreshed', 'true');
            window.location.reload();
        } else {
            setShouldRender(true);
        }
    }, [location]);
  
    if (!shouldRender) {
        <div className={'landing-wrapper'}>
            <Nav />
        </div>
    }
  
    return(
        <div className={'landing-wrapper'}>
            <Nav />
            <LandingPageFin />  
        </div>
    );

  };
  
  export default LandingPageWrapper;