import React, { useState } from 'react';
import { Button, FormControl, FilledInput, InputLabel } from '@mui/material';
import APIUrls from '../_api/APIUrls';
import useAnalyticsEventTracker from '../_common/useAnalyticsEventTracker';

export default function NewsletterSubForm() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const gaEventTracker = useAnalyticsEventTracker('newsletter');

  const subscribe = async () => {
    const baseURL: string = APIUrls.getBackendUrl();

    try {
      const response = await fetch(baseURL + 'subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email, // Match these to .NET backend
          firstName: firstName, // Match these to .NET backend
          lastName: lastName, // Match these to .NET backend
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      console.log('response', response);
      gaEventTracker('newsletter-subscribe');
      setStatus('Subscription successful!');
    } catch (error) {
      console.error('There was an error!', error);
      setStatus('There was an error in subscribing, please try again.');
    }
  };

  return (
    <>
      <div>
        <FormControl margin="dense" fullWidth variant="filled" sx={{ maxWidth: '250px', margin: '.5rem' }}>
          <InputLabel id='given-name'>First Name</InputLabel>
          <FilledInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name="given-name"
            aria-describedby="given-name"
            type="text"
            autoComplete="given-name"
          />
        </FormControl>
        <FormControl margin="dense" fullWidth variant="filled" sx={{ maxWidth: '250px', margin: '.5rem' }}>
          <InputLabel id="family-name">Last Name</InputLabel>
          <FilledInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name="family-name"
            aria-describedby="family-name"
            type="text"
            autoComplete="family-name"
          />
        </FormControl>
        <FormControl margin="dense" fullWidth variant="filled" sx={{ maxWidth: '516px', margin: '.5rem' }}>
          <InputLabel id="email">Email address</InputLabel>
          <FilledInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            aria-describedby="email"
            type="email"
            required
            autoComplete="email"
          />
        </FormControl>
      </div>
      <Button
        onClick={subscribe}
        variant="contained"
        color="error"
        size="large"
        sx={{ marginTop: '1rem', textTransform: 'unset' }}
      >
        Subscribe
      </Button>
      <div>{status}</div>
    </>
  );
}
