import { Container, Stack, Typography } from '@mui/material';
import grace from '../../assets/graceHeadshot.jpg';
import '../../pages/pageStyles/LandingPage.css';
import Divider from './components/Divider';
const title = "Our Founders' Story";
const FoundersStory = () => (
  <>
    <section className={`bg-background py-8`} id="product">
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1 className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}>
          {title.split(' ').map((word, index) => (
            <span key={index} className={index % 2 ? 'text-primary' : 'text-border'}>
              {word}{' '}
            </span>
          ))}
        </h1>
        <Divider />

        <Container maxWidth={false} disableGutters sx={{ my: 5 }}>
          <Container maxWidth="xl" sx={{ my: 5, alignItems: 'center' }}>
            <Typography variant="body1">
              <div id="openDyslexicFont">Hi, My name is Grace and I have Dyslexia.</div>
              <br />
              Dyslexia is a processing disorder marked by a difference in the acquisition of language, and it has
              fundamentally shaped the direction of my life. Dyslexia is terrifying because it's a life sentence,
              there's no pill or magic bullet. You'll have to work a lot harder than your peers to get the same
              outcomes, and somehow stay positive. That's precisely what I had to spend the formative years of my life
              doing. No step of the process was easy, but I am so proud to be graduating with honors with a degree in
              computer science despite the challenges of being a Dyslexic student.
              <br />
              <br />
              The thing about Dyslexia is, once you have your eyes opened to how cruel the world is for people who think
              or communicate differently, you can't go back. Watching the brilliance of my Dyslexic friends and family
              go unnoticed simply because they didn't write in a way that was deemed “acceptable” was when I knew I had
              to do something. I set off to utilize what I have learned as a software engineer to build tools to allow
              Dyslexics to navigate a world not made for them.
              <br />
              <br />
              I built a prototype version of Dyslexico for a competitive coding competition and won the whole thing, and
              shortly after my co-founder Bridget convinced me to take it further. I pitched my idea to the Raikes
              School Design Studio Startup incubator in front of prominent business leaders on the Raikes board. Raikes
              School Board member Mike Cassling believed in the project and sponsored Dyslexico as a startup in the
              Design Studio incubator.
              <br />
              <br />
              Dyslexico is a company made by Dyslexics for Dyslexics. We are developing spelling and grammar correction
              tools specifically oriented toward the Dyslexic experience. We leverage cutting-edge machine learning
              methodologies and the latest Dyslexia research to solve shortcomings of existing tools not designed for
              Dyslexic users. Our solution grows with our users and we would love to have you join us in our mission to
              make the world more accessible for Dyslexics.
              <br />
              <br />
              I'm beyond excited to share it with you all.
              <br />
              <br />
              Thanks to the overwhelming support of the Raikes School, I have an outstanding team working toward that
              mission. We are guided by amazing industry experts including our sponsor Mike Cassling, our exceptional
              coach Bill Anderson.
              <br />
              <br />
              Dyslexic people are everywhere. We are doctors, lawyers, and entrepreneurs, who have all figured out how
              to make it work. When I mention I have Dyslexia in an academic or professional setting folks are
              surprised, but they shouldn't be. Dyslexico aims to provide Dyslexic folks around the world the tools we
              need to thrive at whatever dream we pursue.
            </Typography>
          </Container>
        </Container>
      </div>
    </section>
  </>
);

export default FoundersStory;
