import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import juliaAndAnna from '../../assets/StudentAndTeacher.jpg';
import '../../pages/pageStyles/LandingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faDownload, faInfoCircle, faPaste, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from '../_common/useAnalyticsEventTracker';

const FoundersStory = () => {
  const gaEventTracker = useAnalyticsEventTracker('landingPage');
  return (
    <>
      <Container maxWidth="xl" sx={{ my: 5, alignItems: 'center' }}>
        <Stack alignItems="center" my={4} textAlign={'center'}>
          <Typography variant="h1" my={2} sx={{ fontSize: '3rem' }}>
            What is Dyslexico
          </Typography>
          <Typography variant="body1" maxWidth={'900px'} fontSize={'1.25rem'}>
            Dyslexico is a spellcheck and autocorrect solution <b>optimized for dyslexics</b> that provides higher
            success for dyslexic grammatical patterns, as well as an experience <b>uniquely designed</b> to help teach
            dyslexics to improve their writing skills as they correct.
          </Typography>
        </Stack>
        <Grid container spacing={4} direction="row" my={4} textAlign={'center'}>
          <Grid item xs={12} md={4}>
            <Stack alignItems="center">
              <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'}>
                Import & Check
              </Typography>
              <Box my={3} style={{ display: 'flex', fontSize: '5rem', gap: '1.5rem' }}>
                <FontAwesomeIcon icon={faPaste}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </Box>
              <Typography variant="body1" maxWidth={'350px'}>
                Simply paste in your text and click the check to access state of the art AI powered corrections
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack alignItems="center">
              <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'}>
                Define & Listen
              </Typography>
              <Box my={3} style={{ display: 'flex', fontSize: '5rem', gap: '1.5rem' }}>
                <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faVolumeHigh}></FontAwesomeIcon>
              </Box>
              <Typography variant="body1" maxWidth={'350px'}>
                We know dyslexics are smart! It's just a question of communicating in a way we understand. That's why
                Dyslexico lets you read the definition of and listen to new words.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack alignItems="center">
              <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'}>
                Finalize & Export
              </Typography>
              <Box my={3} style={{ display: 'flex', fontSize: '5rem', gap: '1.5rem' }}>
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
              </Box>
              <Typography variant="body1" maxWidth={'350px'}>
                Finally you can listen to your writing in its entirety, catch any final errors and export it to your
                keyboard or as a document!
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container direction="row" my={4}>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center', maxWidth: '500px' }}>
            <img
              alt="teacher and student using dyslexico"
              id=""
              src={juliaAndAnna}
              style={{
                maxWidth: '500px',
                width: '100%',
                borderRadius: '16px',
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                padding: '0px',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mx={2} maxWidth={'500px'}>
              <Typography variant="h1" sx={{ fontSize: '3rem' }} mb={4}>
                Beyond Traditional Grammar and Spelling
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1.25rem', pb: 4 }}>
                We leverage a unique combination of machine learning and an exclusive dyslexic focus to provide an
                experience perfect for you.
              </Typography>
              <Link
                to="/tutorial"
                onClick={() => {
                  gaEventTracker('viewTutorialBtnClick');
                  window.scroll(0, 0);
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="medium"
                  sx={{ textTransform: 'unset', fontSize: '1.25rem' }}
                >
                  <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                    View Tutorial
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FoundersStory;
