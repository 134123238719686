import { Container, Grid, Stack, Typography } from '@mui/material';
import '../../pages/pageStyles/LandingPage.css';

const WhyDyslexico = () => (
  <>
    <Container maxWidth={false} disableGutters sx={{ pb: 5, mb: 10, color: 'white' }} id="header_around">
      <Container maxWidth="xl" disableGutters>
        <Stack alignItems="center" my={4} textAlign={'center'} sx={{ transform: 'skewY(3.5deg)' }}>
          <Typography variant="h1" my={2} sx={{ fontSize: '3rem', color: 'white' }}>
            Why Dyslexico?
          </Typography>
          <Typography variant="body1" maxWidth={'800px'} fontSize={'1.5rem'} fontWeight={'bold'}>
            Your experience first and foremost.
          </Typography>
        </Stack>
        <Grid container spacing={4} direction="row" my={4} textAlign={'center'} sx={{ transform: 'skewY(3.5deg)' }}>
          <Grid item xs={12} md={4}>
            <Stack alignItems="center">
              <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'} mb={2}>
                AI Powered
              </Typography>
              <Typography variant="body1" maxWidth={'350px'}>
                We are leveraging machine learning and new AI technologies.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack alignItems="center">
              <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'} mb={2}>
                Dyslexia Specific
              </Typography>
              <Typography variant="body1" maxWidth={'350px'}>
                We are targeting a specific market that has been left behind.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack alignItems="center">
              <Typography variant="h2" fontSize={'2rem'} fontWeight={'bold'} mb={2}>
                Lifelong-Companion
              </Typography>
              <Typography variant="body1" maxWidth={'350px'}>
                Our tool can serve as a lifelong companion.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Container>
  </>
);

export default WhyDyslexico;
