import React from 'react';

import Divider from './components/Divider';
import demo from '../../assets/demo_video.gif';
import demo_ext from '../../assets/chrome_ext_demo.png';

const Product = () => {
  const title = 'Spelling and grammar correction made by dyslexics for dyslexics.';
  const firstItem = {
    title: 'What is Dyslexico',
    description:
      'Dyslexico is a spellcheck and autocorrect solution optimized for dyslexics that provides higher success for dyslexic grammatical patterns, as well as an experience uniquely designed to help teach dyslexics to improve their writing skills as they correct.      ',
    img: demo,
  };
  const secondItem = {
    title: 'A new Chrome Extension!',
    description:
    'Take us with you wherever you write best! Our new Chrome Extension provides our awesome corrections on the go!',
    img: demo_ext,
  };
  return (
    <section className={`bg-background py-8`} id="product">
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1 className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}>
          {title.split(' ').map((word, index) => (
            <span
              key={index}
              className={index % 2 ? 'text-primary' : 'text-border '}
              id={word == 'dyslexics' || word == 'dyslexics.' || word == 'for' ? 'openDyslexicFont' : ''}
            >
              {word}{' '}
            </span>
          ))}
        </h1>
        <Divider />
        <div className={`flex flex-wrap`}>
          <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
            <h3 className={`text-3xl text-gray-800 font-bold leading-none mb-3`}>{firstItem?.title}</h3>
            <p className={`text-gray-600`}>{firstItem?.description}</p>
          </div>
          <div className={`w-full sm:w-1/2 p-6`}>
            <img className="h-6/6" src={firstItem?.img} alt={firstItem?.title} />
          </div>
        </div>
        <div className={`flex flex-wrap flex-col-reverse sm:flex-row`}>
          <div className={`w-full sm:w-1/2 p-6`}>
            <img className="h-6/6" src={secondItem?.img} alt={secondItem?.title} />
          </div>
          <div className={`w-full sm:w-1/2 p-6 mt-20`}>
            <div className={`align-middle`}>
              <h3 className={`text-3xl text-gray-800 font-bold leading-none mb-3`}>{secondItem?.title}</h3>
              <p className={`text-gray-600 mb-8`}>{secondItem?.description}</p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="https://chromewebstore.google.com/detail/dyslexico-chrome-extensio/pmpbmdhfeodffagambmfjcbfekofenjg"
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
                  >
                    Try it now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;
