import React from 'react';

import juliaAndAnna from '../../assets/StudentAndTeacher.jpg';
import demo_img from "../../assets/demo_img.png"
const MainHeroImage = () => {
  return (
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="h-96 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        src={juliaAndAnna}
        alt="student and educator using Dyslexico"
      />
    </div>
  );
};

export default MainHeroImage;