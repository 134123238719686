import React from 'react';
import { Footer } from '../components/landingPage/Footer';
// import './pageStyles/LandingPage.css';
import useAnalyticsEventTracker from '../components/_common/useAnalyticsEventTracker';
import MeetTeam from '../components/landingPage/MeetTeam';
import DonateSection from '../components/landingPage/DonateSection';
import AboutUsInfo from '../components/landingPage/AboutUs';
import Canvas from '../components/landingPage/Canvas';
import FoundersStory from '../components/landingPage/FoundersStory';
import LazyShow from '../components/landingPage/LazyShow';
import Quote from '../components/landingPage/Quote';

export function AboutUs() {
  const gaEventTracker = useAnalyticsEventTracker('landingPage');
  gaEventTracker('enterLanding');

  return (
    <div>
      <div id="whole">
        <AboutUsInfo />
        <LazyShow>
          <>
            <FoundersStory />
            <Canvas />
          </>
        </LazyShow>
        <LazyShow>
          <>
            <MeetTeam />
          </>
        </LazyShow>
        <LazyShow>
          <Quote />
        </LazyShow>
        <LazyShow>
          <>
            <DonateSection />
            <Canvas />
          </>
        </LazyShow>
      </div>
    </div>
  );
}
