import React from 'react';
import "./PricingTable.css"
import premiumPicture from "../../assets/dyslexico_premium.png";
import litePicture from "../../assets/dyslexico_lite.png";
import educatorPicture from "../../assets/dyslexico_educator2.png";
import { Button } from '@mui/material';
import { AccountInfo } from '@azure/msal-common';
import UserAPI from '../_api/UserAPI';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import classNames from 'classnames';
// Props for the PricingTable component
interface PricingTableProps {
    userHasPremium: boolean | null;
}


// PricingTable Component
const PricingTable: React.FC<PricingTableProps> = ({userHasPremium}) => {
    const redirectToContact = () => {
        window.location.href = "https://dyslexi.co/contact";
      };
      const redirectToEditor = () => {
        window.location.href = "https://dyslexi.co/textEditor";
      };
    const handlePremiumLogic = () => {
    if(userId == "unknown id") {
        userAPI.handleLoginRedirect(instance)
        return;
    }
    if (userHasPremium === null) {
        console.error('Please wait for premium status to be determined');
        return;
    }
    if (userHasPremium) {
        userAPI.createCustomerPortal(userId);
    } else {
        userAPI.createCheckoutSession(userId);
    }
    };
    const userAPI = new UserAPI();
    let activeAccount: AccountInfo | null = null;
    const { instance } = useMsal();
    activeAccount = instance.getActiveAccount();
    var userId = activeAccount?.localAccountId || 'unknown id';
    return (
        <div className="card-container">
                <div className="card">
                <img src={litePicture} alt={"Dyslexico Lite"} className="card-image" />
                <div className="description">
                    <h2>Dyslexico Lite</h2>
                    <p>Try Dyslexico for free and write with confidence with tools designed for dyslexia.</p>
                </div>
                <div className="price-container">
                    <h1>$0</h1>
                    <p className="inline-paragraph"> per month</p> 
                </div>
                <AuthenticatedTemplate>
                        <Button
                            id="subscribe_button"
                            onClick={() => redirectToEditor()}
                            variant="contained"
                            sx={{ textTransform: 'unset', fontSize: '1rem' }}>
                            Try Now
                        </Button> 
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <Button
                        id="subscribe_button"
                        onClick={() => userAPI.handleLoginRedirect(instance)}
                        variant="contained"
                        sx={{ textTransform: 'unset', fontSize: '1rem' }}>
                        Sign in
                    </Button>  
                </UnauthenticatedTemplate>
                <div className="feature-list">
                    <h3>Includes:</h3>
                    <ul>
                    <li>Check 3 samples of writing per day with Dyslexico</li>
                    <li>Write with dyslexia-friendly fonts</li>
                    <li>Use Dyslexico in a chrome extension where you write best</li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <img src={premiumPicture} alt={"Dyslexico Premium"} className="card-image" />
                <div className="description">
                    <h2>Dyslexico Premium</h2>
                    <p>Write with confidence and learn from your mistakes with unlimited access to Dyslexico's writing correction model and error analytics.</p>
                </div>
                <div className="price-container">
                    <h1>$10</h1>
                    <p> per month</p> 
                </div>
                <AuthenticatedTemplate>
                {userHasPremium ? (
                <Button
                    id="subscribe_button"
                    onClick={() => handlePremiumLogic()}
                    variant="contained"
                    disabled={userHasPremium == null}
                    sx={{ textTransform: 'unset', fontSize: '1rem' }}
                    >
                    Manage Account
                </Button>
                ) : (
                    <Button
                    id="subscribe_button"
                    onClick={() => handlePremiumLogic()}
                    variant="contained"
                    disabled={userHasPremium == null}
                    sx={{ textTransform: 'unset', fontSize: '1rem' }}
                    >
                    Subscribe
                </Button>
                )}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Button
                        id="subscribe_button"
                        onClick={() => userAPI.handleLoginRedirect(instance)}
                        variant="contained"
                        sx={{ textTransform: 'unset', fontSize: '1rem' }}>
                        Subscribe 
                    </Button>  
                </UnauthenticatedTemplate>
                <div className="feature-list">
                    <h3>Includes:</h3>
                    <ul>
                    <li>Check an unlimited number of writings with Dyslexico</li>
                    <li>Receive individualized error analytics to learn from mistakes</li>
                    <li>Access speech-to-text, text-to-speech, and tools designed for dyslexia</li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <img src={educatorPicture} alt={"Dyslexico Educator"} className="card-image" />
                <div className="description">
                    <h2>Dyslexico Educator</h2>
                    <p>Empower your teachers with powerful educator analytics and students with writing tools designed for dyslexia.</p>
                </div>
                <div className="price-container">
                </div>
                <Button
                    id="subscribe_button"
                    onClick={() => redirectToContact()}
                    variant="contained"
                    sx={{ textTransform: 'unset', fontSize: '1rem' }}
>
                    Contact
                </Button>
                <div className="feature-list">
                    <h3>Includes:</h3>
                    <ul>
                    <li>Give Dyslexico Premium accounts to all students in your organization</li>
                    <li>Provide writing analytics to educators to empower data-driven dyslexia intervention</li>
                    </ul>
                </div>
            </div>
      </div>
    );  
};

export default PricingTable;
