class APIUrls {
  static prodURL: string = 'https://2023-dyslexico-csharp-production.azurewebsites.net/api/Dyslexico/';
  static localURL: string = 'https://localhost:50721/api/Dyslexico/';
  static localTesting = false;

  static getBackendUrl() {
    if (this.localTesting) {
      return this.localURL;
    } else {
      return this.prodURL;
    }
  }
}
export default APIUrls;