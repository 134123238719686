import ReactGA from 'react-ga4';
import { useMsal } from '@azure/msal-react';
import APIUrls from "../_api/APIUrls"

type GAEventData = {
  category: string;
  action: string;
  label: string;
  // user_id?: string;
  [key: string]: string | number | boolean; // Flexible custom parameter support
};

const useAnalyticsEventTracker = (category = 'Blog category') => {
  let localDev = APIUrls.localTesting;
  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();
  let accountId = "unauthenticated"
  if (activeAccount?.localAccountId) {
    accountId = activeAccount?.localAccountId;
  }
  const eventTracker = (action = 'test action', label = accountId) => {
    const eventData: GAEventData = {
      category, // Although GA4 is more flexible, you can still use "category" for organizational purposes
      action,
      label,
    };

    if (activeAccount?.localAccountId) {
      ReactGA.set({ userId: activeAccount?.localAccountId, logged_in: true })
    }
    else {
      ReactGA.set({ userId: null, logged_in: false })
    }
    if (!localDev) {
      ReactGA.event(eventData);
    }

  };

  return eventTracker;
};

export const initializeGA = (measurementID: string) => {
  ReactGA.initialize(measurementID);
};

export default useAnalyticsEventTracker;
