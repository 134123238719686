import { Container, Grid, Stack, Typography } from '@mui/material';
import grace from '../../assets/graceHeadshot.jpg';
import bridget from '../../assets/bridgetHeadshot.jpg';
import tristan from '../../assets/tristanHeadshot.jpg';
import bill from '../../assets/billHeadshot.jpg';

const FoundersStory = () => (
  <>
    <Container maxWidth="xl" sx={{ my: 5, textAlign: 'center' }}>
      <Typography variant="h1" my={2} sx={{ fontSize: '3rem' }}>
        Our Leadership Team
      </Typography>
      <Grid container spacing={4} direction="row" justifyContent="center" mb={4} textAlign="center">
        <Grid item xs={12} sm={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Grace Clausen"
              style={{
                width: '150px',
                border: '1px solid #dcdfe2',
                borderRadius: '50%',
                padding: 0,
              }}
              src={grace}
            />
            <Typography variant="h2" fontSize="1.5rem" fontWeight="bold" pt={2} pb={1}>
              Grace Clausen
            </Typography>
            <Typography variant="body1" maxWidth="350px">
              Co-founder & Chief Executive Officer
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Bridget Peterkin"
              style={{
                width: '150px',
                border: '1px solid #dcdfe2',
                borderRadius: '50%',
                padding: 0,
              }}
              src={bridget}
            />
            <Typography variant="h2" fontSize="1.5rem" fontWeight="bold" pt={2} pb={1}>
              Bridget Peterkin
            </Typography>
            <Typography variant="body1" maxWidth="350px">
              Co-founder & Chief Technical Officer
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Tristan Curd"
              style={{
                width: '150px',
                border: '1px solid #dcdfe2',
                borderRadius: '50%',
                padding: 0,
              }}
              src={tristan}
            />
            <Typography variant="h2" fontSize="1.5rem" fontWeight="bold" pt={2} pb={1}>
              Tristan Curd
            </Typography>
            <Typography variant="body1" maxWidth="350px">
              Co-founder & Chief Operations Officer
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Stack alignItems="center">
            <img
              alt="Bill Anderson"
              style={{
                width: '150px',
                border: '1px solid #dcdfe2',
                borderRadius: '50%',
                padding: 0,
              }}
              src={bill}
            />
            <Typography variant="h2" fontSize="1.5rem" fontWeight="bold" pt={2} pb={1}>
              Bill Anderson
            </Typography>
            <Typography variant="body1" maxWidth="350px">
              Coach and Advisor
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </>
);

export default FoundersStory;
