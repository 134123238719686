import React from 'react';
import { Footer } from '../components/landingPage/Footer';
// import './pageStyles/LandingPage.css';
import useAnalyticsEventTracker from '../components/_common/useAnalyticsEventTracker';
import HeaderSection from '../components/landingPage/HeaderSection';
import CallToAction from '../components/landingPage/CallToAction';
import WhatsDyslexico from '../components/landingPage/WhatsDyslexico';
import WhyDyslexico from '../components/landingPage/WhyDyslexico';
import FoundersStory from '../components/landingPage/FoundersStory';
import MeetTeam from '../components/landingPage/MeetTeam';
import ChromeExtSection from '../components/landingPage/ChromeExtSection';
import NewsletterSubSection from '../components/landingPage/NewsletterSubSection';
import DonateSection from '../components/landingPage/DonateSection';

export function LandingPage() {
  const gaEventTracker = useAnalyticsEventTracker('landingPage');
  gaEventTracker('enterLanding');

  return (
    <div>
      <div id="whole">
        <HeaderSection />
        <CallToAction />
        <WhatsDyslexico />
        <ChromeExtSection />
        <NewsletterSubSection />
        <WhyDyslexico />
        <FoundersStory />
        <MeetTeam />
        <DonateSection />
      </div>
      <Footer />
    </div>
  );
}

