import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig, protectedResources } from "./authconfig";


const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (event.error?.name === "InteractionRequiredAuthError" && window.location.pathname.startsWith(protectedResources.apiBapi.path)) {
    } else {
      console.log("ACQUIRE_TOKEN_FAILURE");
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error?.name === "BrowserAuthError" && window.location.pathname.startsWith(protectedResources.apiBapi.path)) {
    } else {
      console.log("LOGIN FAILURE");
    }
  } else {
    console.log("Callback finished");
  }
});
root.render(
  <React.StrictMode>
    <App pca={msalInstance}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
