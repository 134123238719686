import React from 'react';
import { faCheck, faInfoCircle, faLightbulb, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Features = () => {
  const subtitle = 'Beyond traditional spelling and grammar correction';
  const title = 'The Latest Innovations in AI technology';
  const description =
    'We leverage a unique combination of machine learning and an exclusive dyslexic focus to provide an experience perfect for you.  ';
  const featuresList = [
    {
      name: '3x more errors accurately corrected',
      description:
        'Whereas our competitors correct less than half of errors dyslexic individuals make, Dyslexico corrects around 80% of dyslexic errors.',
      icon: faCheck,
    },
    {
      name: 'Analytics Dashboard',
      description:
        'Dyslexico helps students and teachers learn as you write. All errors made are tracked and classified on our analytics dashboard',
      icon: faInfoCircle,
    },
    {
      name: 'Your ideas. Your voice',
      description:
        'Most AI tools have you prompt them to create ideas. Dyslexico is carefully designed to correct your writing while maintaining your ideas and your voice. ',
      icon: faLightbulb,
    },
    {
      name: 'Text to speech',
      description:
        'Dyslexico provides state of the art speech to text and text to speech to allow you to communicate however is easiest for you.',
      icon: faVolumeHigh,
    },
  ];

  return (
    <div className={`py-12 bg-background`} id="features">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className={`text-base text-primary font-semibold tracking-wide uppercase`}>{title}</h2>
          <p id="openDyslexicFont" className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{subtitle}</p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">{description}</p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {featuresList.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div
                    className={`absolute flex items-center justify-center h-12 w-12 rounded-md bg-background text-tertiary border-primary border-4`}
                  >
                    <FontAwesomeIcon
                      className={`inline-block h-6 w-6 rounded-full`}
                      icon={feature.icon}
                    ></FontAwesomeIcon>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;
