import React from 'react';

import Divider from './components/Divider';
import team_pic from '../../assets/modern_formats/dyslexico team.webp';
import presenter_pic from '../../assets/modern_formats/dyslexico_newventure.webp';

const AboutUsInfo = () => {
  const title = 'About Us';
  const firstItem = {
    title: 'Who is Dyslexico',
    description:
      'Dyslexico, an innovative project incubated within the University of Nebraska–Lincoln’s Jeffrey S. Raikes School of Computer Science and Management, is reshaping the landscape of writing tools for individuals with dyslexia. Developed by a dedicated team of Huskers, Dyslexico offers a spell and grammar check program uniquely tailored to the needs of dyslexic users.',
    img: team_pic,
  };
  const secondItem = {
    title: '',
    description:
      'Dyslexico\'s journey has been marked by notable achievements, including winning the university’s New Venture Competition and securing funding discussions with state legislators. These successes not only validate the project\'s impact but also amplify its reach and accessibility to those who stand to benefit most.',
    img: presenter_pic,
  };
  return (
    <section className={`bg-background py-8`} id="product">
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1 className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}>
          {title.split(' ').map((word, index) => (
            <span key={index} className={index % 2 ? 'text-primary' : 'text-border'}>
              {word}{' '}
            </span>
          ))}
        </h1>
        <Divider />
        <div className={`flex flex-wrap`}>
          <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
            <h3 className={`text-3xl text-gray-800 font-bold leading-none mb-3`}>{firstItem?.title}</h3>
            <p className={`text-gray-600`}>{firstItem?.description}</p>
          </div>
          <div className={`w-full sm:w-1/2 p-6`}>
            <img className="h-6/6" src={firstItem?.img} alt={firstItem?.title} />
          </div>
        </div>
        <div className={`flex flex-wrap flex-col-reverse sm:flex-row`}>
          <div className={`w-full sm:w-1/2 p-6`}>
            <img className="h-6/6" src={secondItem?.img} alt={secondItem?.title} />
          </div>
          <div className={`w-full sm:w-1/2 p-6 mt-20`}>
            <div className={`align-middle`}>
              <h3 className={`text-3xl text-gray-800 font-bold leading-none mb-3`}>{secondItem?.title}</h3>
              <p className={`text-gray-600 mb-8`}>{secondItem?.description}</p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="/textEditor"
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
                  >
                    Try it now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsInfo;
