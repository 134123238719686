import * as React from 'react';
import { useState } from 'react'; // Add useState import
import { Button, Container, Typography } from '@mui/material';

import { InputAdornment, List, ListItem, ListItemButton, ListItemText, OutlinedInput, Tooltip } from '@mui/material';
import { faFileClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SignInNav from '../assets/tutorial_images/SignInNav.png';
import SignInPage from '../assets/tutorial_images/SignInPage.png';
import CheckWritingAnnotated from '../assets/tutorial_images/CheckWritingAnnotated.png';
import CorrectionsListAnnotated from '../assets/tutorial_images/CorrectionsListAnnotated.png';
import ToolsTTSandSTT from '../assets/tutorial_images/ToolsTTSandSTT.png';
import FontOptions from '../assets/tutorial_images/FontOptions.png';
import SaveDocuments from '../assets/tutorial_images/SaveDocuments.png';
import AnalyticsDashboard from '../assets/tutorial_images/AnalyticsDashboard.png';
import FeedbackModalImage from '../assets/tutorial_images/FeedbackModalImage.png';
import LazyShow from '../components/landingPage/LazyShow';
export default function Tutorial() {
  const ExampleString = `Welcome, this is you're first exampel sentence. Type in the editor to get started and click check writing on the write to recieve corrections!`;
  const [isCopied, setIsCopied] = useState(false); // Add state for copied status

  const CopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true); // Set copied status to true
    setTimeout(() => {
      setIsCopied(false); // Reset copied status after 3 seconds
    }, 3000);
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ my: 2 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: '20px' }}>
            <h1 className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}>
              Welcome to Dyslexico!
            </h1>
            <div className={`flex flex-wrap`}>
              <div>
                <h3 className={`text-3xl text-gray-800 font-bold leading-none mb-3`}>Getting Started Guide</h3>
                <p>
                  Dyslexico is an assistive writing platform designed for people with dyslexia. Dyslexico leverages the
                  power of our custom corrective AI model, dyslexia-tailored accessibility suite, and powerful analytics
                  to help people with dyslexia correct their writing and communicate with confidence. This guide will
                  help you get started with Dyslexico and learn about our features and tools. If you have any further
                  questions, please reach out to{' '}
                  <a href="mailto: dyslexicosolutions@gmail.com">dyslexicosolutions@gmail.com</a> for support.
                </p>
                <LazyShow>
                  <>
                    <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                      Sign Up for an Account{' '}
                    </Typography>

                    <ul>
                      <li>Click the “Sign in” button on the top right of the screen.</li>
                      <img
                        src={SignInNav}
                        alt="Sign in button"
                        style={{
                          maxWidth: '80%',
                          borderRadius: '1rem',
                          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                          padding: '0px',
                          marginBlock: '2rem',
                        }}
                      />
                      <li>Click the “Sign up now” link or sign in with an existing Google account</li>
                      <img
                        src={SignInPage}
                        alt="Sign In Page"
                        style={{
                          maxWidth: '80%',
                          borderRadius: '16px',
                          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                          padding: '0px',
                          marginBlock: '20px',
                        }}
                      />
                    </ul>
                  </>
                </LazyShow>
                <LazyShow>
                  <>
                    <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                      Check Your First Writing{' '}
                    </Typography>

                    <p>
                      To check writing in Dyslexico, either copy or paste your writing into the text box or write
                      directly in Dyslexico.
                    </p>
                    <p>To get started copy the sentence below into Dyslexico:</p>
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position="end">
                          <Tooltip title={isCopied ? 'Copied!' : 'Copy'} placement="top" arrow>
                            <FontAwesomeIcon
                              icon={faFileClipboard}
                              onClick={() => CopyToClipboard(ExampleString)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </InputAdornment>
                      }
                      value={ExampleString}
                      readOnly
                      fullWidth
                    />
                    <p>Click check writing button and wait for AI model to generate corrections</p>
                    <img
                      src={CheckWritingAnnotated}
                      alt="Check Writing"
                      style={{
                        maxWidth: '80%',
                        borderRadius: '16px',
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        padding: '0px',
                        marginBlock: '20px',
                      }}
                    />
                    <p>Scroll through the corrections. Click on the green box to insert the correction.</p>
                    <img
                      src={CorrectionsListAnnotated}
                      alt="Corrections"
                      style={{
                        maxWidth: '80%',
                        borderRadius: '16px',
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        padding: '0px',
                        marginBlock: '20px',
                      }}
                    />
                    <p>Check your own writing into Dyslexico!</p>
                  </>
                </LazyShow>
                <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                  Try out Dyslexico's Tools{' '}
                </Typography>

                <ul>
                  <li>Read your writing out loud using the Text-To-Speech widget.</li>
                  <li>Speak into Dyslexico using the Speech-To-Text widget.</li>
                  <img
                    src={ToolsTTSandSTT}
                    alt="Tools"
                    style={{
                      maxHeight: '650px',
                      borderRadius: '16px',
                      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                      padding: '0px',
                      marginBlock: '20px',
                    }}
                  />
                  <li>Change font to Open-Dyslexic Font.</li>
                  <img
                    src={FontOptions}
                    alt="Tools"
                    style={{
                      maxWidth: '80%',
                      borderRadius: '16px',
                      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                      padding: '0px',
                      marginBlock: '20px',
                    }}
                  />
                </ul>
                <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                  Save Your First Document{' '}
                </Typography>

                <ol>
                  <li>
                    While on the text editor, go to the document toolbar: Select File &gt; Save to save your document.
                  </li>
                  <li>View your document in the account page under “My Documents”</li>
                </ol>
                <img
                  src={SaveDocuments}
                  alt="Tools"
                  style={{
                    maxWidth: '80%',
                    borderRadius: '16px',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    padding: '0px',
                    marginBlock: '20px',
                  }}
                />
                <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                  Check out Analytics Dashboard{' '}
                </Typography>

                <p>
                  After you have begun to write in Dyslexico, check out your analytics dashboard to see a breakdown of
                  the corrections Dyslexico has made and understand what type of errors you are making.
                </p>
                <img
                  src={AnalyticsDashboard}
                  alt="Tools"
                  style={{
                    maxWidth: '80%',
                    borderRadius: '16px',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    padding: '0px',
                    marginBlock: '20px',
                  }}
                />
                <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                  Provide Feedback{' '}
                </Typography>

                <p>
                  See something wrong? Tell us about it! Click the flag icon on the correction and tell us how we're
                  doing. We want to learn the best ways to help you, so we will be looking at the responses and making
                  improvements to make Dyslexico the best it can be. If you want to talk to anyone on the team more
                  about your experience, reach out to{' '}
                  <a href="mailto: dyslexicosolutions@gmail.com">dyslexicosolutions@gmail.com</a> for support.
                </p>
                <img
                  src={FeedbackModalImage}
                  alt="Tools"
                  style={{
                    maxWidth: '80%',
                    borderRadius: '16px',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    padding: '0px',
                    marginBlock: '20px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
