import React from 'react';
import '../../pages/pageStyles/tos.css';
import { Box, Grid, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import '../../pages/pageStyles/ContactUs.css';

export function Footer() {
  const copyrightYear = new Date().getFullYear(); // returns the current year
  return (
    <>
      <footer
        id="footer"
        style={{
          textAlign: 'center',
          paddingBlock: '1rem',
          backgroundColor: '#e4e4e4',
          color: '#4d536e',
          boxShadow: '0 -1px 0 #000;',
          width: '100%',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container columns={3}>
            <Grid item xs={1}>
              <Stack direction="column" spacing={2}>
                <b>Contact Us</b>
                <Link to="/contact">Business Inquiries</Link>
                <HashLink smooth to="/contact">
                  Newsletter
                </HashLink>
                <Link to="/feedback">Feedback</Link>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack direction="column" spacing={2}>
                <b>Company</b>
                <Link to="/tos">Terms Of Service</Link>
                <Link to="/tos">Privacy Policy</Link>
                <Link to="/tos">COPPA Compliance</Link>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack direction="column" spacing={2}>
                <b>Connect</b>
                <Link to="https://www.linkedin.com/company/dyslexico" target="_blank">
                  <FontAwesomeIcon icon={faLinkedin as IconProp} /> LinkedIn
                </Link>
                <Link to="https://twitter.com/dyslexi_co" target="_blank">
                  <FontAwesomeIcon icon={faTwitter as IconProp} /> Twitter
                </Link>
                <Link to="https://www.facebook.com/profile.php?id=100092997969808" target="_blank">
                  <FontAwesomeIcon icon={faFacebook as IconProp} /> Facebook
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <p>&copy; {copyrightYear} Dyslexico</p>
      </footer>
    </>
  );
}
