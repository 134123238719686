import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import '../../pages/pageStyles/LandingPage.css';
import ChromeExtExample from '../../assets/ChromeExtExample.jpg';
import useAnalyticsEventTracker from '../_common/useAnalyticsEventTracker';

function ChromeExtSection() {
  const gaEventTracker = useAnalyticsEventTracker('landingPage');
  return (
    <>
      <Container maxWidth="xl" sx={{ textAlign: 'center', paddingTop: '2rem' }}>
        <Typography variant="h1" mb={2} sx={{ fontSize: '3rem' }}>
          Dyslexico Chrome Extension Now Available!
        </Typography>
        <Typography variant="body1" my={2} sx={{ fontSize: '1.25rem' }}>
          Get the power of Dyslexico wherever you write best. Correct your writing with Dyslexico on any site you visit
          with our new <b>Chrome Extension</b>!
        </Typography>
        <img
          alt="Chrome Extension Example"
          id=""
          src={ChromeExtExample}
          style={{
            maxWidth: '800px',
            width: '100%',
            borderRadius: '16px',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            padding: '0px',
          }}
        />
        <div style={{ margin: '2rem' }}>
          <a
            href="https://chromewebstore.google.com/detail/dyslexico-chrome-extensio/pmpbmdhfeodffagambmfjcbfekofenjg?hl=en-US&utm_source=ext_sidebar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="error"
              size="large"
              sx={{ textTransform: 'unset', fontSize: '1.25rem' }}
              onClick={() => {
                gaEventTracker('chromeExtBtnClick');
              }}
            >
              <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                Download Here
              </Typography>
            </Button>
          </a>
        </div>
      </Container>
    </>
  );
}

export default ChromeExtSection;
