import * as React from 'react';
import './Nav.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPenToSquare, faUser } from '@fortawesome/free-regular-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import UserAPI from '../_api/UserAPI';
import logo from '../../assets/modern_formats/dyslexico_transparent.webp';
import { Button } from '@mui/material';

export default function Nav() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const userAPI = new UserAPI();
  const { instance } = useMsal();

  const appList = (
    <>
      <Link to="/" aria-label="Home Page">
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primaryTypographyProps={{ fontSize: '20px' }}>
              <FontAwesomeIcon icon={faHouse} style={{ marginRight: '8px' }} />
              Home
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </Link>
      <Link to="/textEditor" aria-label="Text Editor Page">
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primaryTypographyProps={{ fontSize: '20px' }}>
              <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: '8px' }} />
              TextEditor
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </Link>
      <Link to="/feedback" aria-label="Feedback Page">
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primaryTypographyProps={{ fontSize: '20px' }}>
              <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
              Feedback
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </Link>
      <AuthenticatedTemplate>
        <Link aria-label="My Account Page" to="/account">
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primaryTypographyProps={{ fontSize: '20px' }}>
                <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
                My Account
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Link aria-label="Sign In" to="/account" onClick={() => userAPI.handleLoginRedirect(instance)}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primaryTypographyProps={{ fontSize: '20px' }}>
                <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
                Sign in
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
      </UnauthenticatedTemplate>
    </>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ my: 2 }}>
        <Link to="/">
          <img style={{ height: '30px' }} alt="Dyslexico logo" src={logo} draggable={false}></img>
        </Link>
      </Box>
      <List>{appList}</List>
    </Box>
  );

  return (
    <>
      <AppBar component="nav" position="sticky" color="default" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1 }} aria-hidden="true">
            <Link to="/">
              <Button disableRipple>
                <img style={{ height: '30px', padding: '0' }} alt="Dyslexico logo" src={logo} draggable={false}></img>
              </Button>
            </Link>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open navigation links dropdown"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <List
              disablePadding
              sx={{ display: 'flex', flexDirection: 'row', fontSize: '20px', backgroundColor: 'unset' }}
              aria-label="navigation links"
            >
              {appList}
            </List>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          anchor="top"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}
