import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from '../../authconfig';
import useAnalyticsEventTracker from '../_common/useAnalyticsEventTracker';
import APIUrls from '../_api/APIUrls';
// Class to access Azure account management endpoint and access user information from business API
class UserAPI {
  baseURL: string = APIUrls.getBackendUrl();
  gaEventTracker = useAnalyticsEventTracker('userAPI');
  // Post new user into database upon sign up
  async postNewUserToDatabase(id: string, email: string, username: string, source: string) {
    try {
      let u = { Id: id, Email: email, UserName: username, Source: source };
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(u),
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const newUserSaveUrl = `${this.baseURL}PostNewUser`;
      await fetch(newUserSaveUrl, requestOptions);
    } catch {
      console.log('error posting user');
    }
  }

  // Get a new user by Azure id
  async getUserById(id: string) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const newUserSaveUrl = `${this.baseURL}GetUserById?userId=${id}`;
    let response = await fetch(newUserSaveUrl, requestOptions);
    return response;
  }
  // Get a new user by Azure id
  async getAllUsers() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const newUserSaveUrl = `${this.baseURL}GetAllUsers`;
    let response = await fetch(newUserSaveUrl, requestOptions);
    return response;
  }

  // Get the user entry from database under an email
  async getUserByEmail(email: string) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const newUserSaveUrl = `${this.baseURL}GetUserByEmail?Email=${email}`;
    let response = await fetch(newUserSaveUrl, requestOptions);
    if (response.ok) {
      const text = await response.text();
      if (text) {
        const json = JSON.parse(text);
        return json;
      } else {
        return null;
      }
    } else {
      throw new Error('Failed to get user by email');
    }
  }

  async getPremiumStatus(userId: string) {
    const url = `${this.baseURL}GetPremiumStatus?userId=${userId}`;
    const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    if (response.ok) {
      const json = await response.json();
      return json.premiumStatus;
    } else {
      throw new Error('Failed to get premium status for user');
    }
  }

  async createCheckoutSession(userId: string) {
    this.gaEventTracker('createCheckoutSession');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${this.baseURL}CreateCheckoutSession?userId=${userId}`;
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const json = await response.json();
      const url = json.checkoutSession;
      if (url != null) {
        window.location.href = url;
      } else {
        throw new Error('url was null');
      }
    } else {
      throw new Error('Failed to create Checkout Session');
    }
  }

  async createCustomerPortal(userId: string) {
    this.gaEventTracker('createCustomerPortal');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const url = `${this.baseURL}CreateCustomerPortal?userId=${userId}`;
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      const json = await response.json();
      const url = json.customerPortal;
      window.location.href = url;
    } else {
      // TODO: make this look better than a basic alert
      alert('It looks like your Premium account is associated with an organization. Please contact your organization to make updates to your Premium subscription or dyslexicosolutions@gmail.com for additional support.');
    }
  }

  // Trigger a login popup where the user can login or signup
  handleLoginPopup(instance: IPublicClientApplication) {
    this.gaEventTracker('loginPopup');
    instance
      .loginPopup({
        ...loginRequest,
      })
      .catch((error) => console.log(error));
  }

  // Trigger a login redirect where user can login or signup
  handleLoginRedirect(instance: IPublicClientApplication) {
    this.gaEventTracker('loginRedirect');
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  }

  // Trigger redirect that logs user out of account
  handleLogoutRedirect(instance: IPublicClientApplication) {
    this.gaEventTracker('logoutRedirect');
    instance.logoutRedirect();
  }

  // Trigger logout popup that logs user out of account
  handleLogoutPopup(instance: IPublicClientApplication) {
    this.gaEventTracker('loginPopup');
    instance.logoutPopup({
      mainWindowRedirectUri: '/', // redirects the top level app after logout
    });
  }

  async getCanRecordInformation(userId: string) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const getCanRecordInfoUrl = `${this.baseURL}GetCanRecordInformation?userId=${userId}`;
    let response = await fetch(getCanRecordInfoUrl, requestOptions);
    if (response.status === 200) {
      let data = await response.json();
      return data.canRecordInformation;
    } else {
      return response;
    }
  }
  async updateCanRecordInformation(userId: string, canRecordInformation: boolean) {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const getCanRecordInfoUrl = `${this.baseURL}UpdateCanRecordInformation?userId=${userId}&canRecordInformation=${canRecordInformation}`;
    try {
      let response = await fetch(getCanRecordInfoUrl, requestOptions);
      if (response.status === 200) {
        return;
      } else {
        throw new Error(`Failed to update can record information for user ${userId}`);
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * updates the check for all users only if the email is an admin. Currently not in use
   * @param account
   * @returns
   */
  async updateCheck(account: any) {
    let email = account && account.username ? account.username : 'unknown';
    if (email === 'gclausen027@gmail.com' || 'tristan02c@gmail.com' || 'bridgetpeterkin@gmail.com') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const checkUpdateUrl = `${this.baseURL}UpdateChecks`;
      let response = await fetch(checkUpdateUrl, requestOptions);
      return response;
    }
  }
}
export default UserAPI;
