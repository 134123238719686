import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from '../_common/useAnalyticsEventTracker';

const Donate = () => {
  const gaEventTracker = useAnalyticsEventTracker('landingPage');

  return (
    <>
      <Container maxWidth="lg" sx={{ textAlign: 'center', padding: '2rem' }}>
        <Typography variant="h1" sx={{ fontSize: '3rem' }}>
          Donate
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.25rem' }} m={2}>
          <b>Together, we can create a more inclusive world for individuals with dyslexia.</b>
          <br />
          Your donation will help us develop AI-powered spelling and grammar corrections and dyslexia accessibility
          tools to help people with dyslexia communicate with confidence.
        </Typography>
        <Link
          to="https://donate.stripe.com/8wM15M8Xk7PA8k85kk"
          target="_blank"
          onClick={() => {
            gaEventTracker('donateBtnClick');
          }}
        >
          <Button variant="contained" color="error" size="large" sx={{ textTransform: 'unset', fontSize: '1.25rem' }}>
            <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
              Donate
            </Typography>
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default Donate;
