import { Container, Grid, Typography } from '@mui/material';
import demo from '../../assets/demo_video.gif';

const CallToAction = () => (
  <>
    <Container maxWidth="xl" disableGutters sx={{ pb: 5 }}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={5}>
          <div style={{ width: '60%', maxWidth: '420px', margin: 'auto' }}>
            <Typography variant="h1" sx={{ fontSize: '3rem' }}>
              Made by Dyslexics For Dyslexics!
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.25rem' }}>
              Experience custom spelling and grammar correction at your fingertips today!
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <img
            alt="TextEditor preview video"
            src={demo}
            style={{
              maxHeight: '100vh',
              width: '100%',
              borderRadius: '16px',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
              padding: '0px',
            }}
          />
        </Grid>
      </Grid>
    </Container>
  </>
);

export default CallToAction;
