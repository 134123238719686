import React from 'react';

const MainHero = () => {
  return (
    <main >
      <div className="px-3 sm:text-center lg:text-left font-family:openDyslexic">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Accessibility built</span>{' '}
          <span  id="openDyslexicFont" className={`block text-primary xl:inline`}> for dyslexia.</span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          Introducing Dyslexico, the world's first AI-powered spelling and grammar accessibility tool designed
          specifically for dyslexia. Trained on the largest corpus of real dyslexic writing, Dyslexico offers
          unparalleled corrections tailored to the dyslexic mind. Say goodbye to frustration and hello to confident
          communication with Dyslexico, your key to accessible and inclusive communication.
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <a
              href={'/textEditor'}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              Get started
            </a>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href="/contact"
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              Join our Newsletter 
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainHero;
