import { Container, Typography } from '@mui/material';
import '../../pages/pageStyles/LandingPage.css';
import NewsletterSubForm from '../newsletter/NewsletterSubForm';

const NewsletterSubSection = () => (
  <>
    <Container maxWidth="xl" sx={{ textAlign: 'center', paddingTop: '2rem' }} id="newsletter">
      <Typography variant="h1" sx={{ fontSize: '3rem' }}>
        Subscribe to Our Newsletter
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '1.25rem' }}>
        Join our newsletter to stay up to date on our progress and new features!
      </Typography>
      <NewsletterSubForm />
    </Container>
  </>
);

export default NewsletterSubSection;
