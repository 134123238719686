import { Container, Stack, Typography } from '@mui/material';
import grace from '../../assets/grace_grad.jpg';
import '../../pages/pageStyles/LandingPage.css';
import Divider from './components/Divider';
const Quote = () => (
  <>
    <Stack alignItems="center" my={4} textAlign={'center'} sx={{ backgroundColor: '#a42424' }} color={'white'} p={3}>
      <img
        alt="CEO Grace Clausen"
        style={{ width: '150px', borderRadius: '50%', padding: 0 }}
        src={grace}
      />
      <Typography variant="body1" fontSize={'1.5rem'} my={2} maxWidth="xl">
        "A diagnosis with dyslexia can be terrifying, however, people with dyslexia are brilliant and we are working to
        provide the tools so the rest of the world can see that."
      </Typography>
      <Typography variant="body1">Grace Clausen, Co-founder & CEO</Typography>
    </Stack>
  </>
);

export default Quote;
